import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Meta from '../../shared/Meta';
import TermsText from '../../shared/TermsText';

// Actions
import { clearServerRenderedPath } from '../../../actions/uiActions';

// Selectors
import { getCityConfig } from '../../../selectors';

// Misc
import IsoMorphUtils from '../../../modules/isoMorphUtils';
import styles from '../../../../stylesheets/public/layouts/AboutPage.module.postcss';

class Terms extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (IsoMorphUtils.serverRendered(this.props)) {
      this.props.clearServerRenderedPath();
    }
  }

  render() {
    const { location, cityConfig } = this.props;

    return (
      <div>
        <Meta
          title={`Terms & Conditions - ${cityConfig.site_title}`}
          structuredData={{
            breadcrumbs: { listItems: [
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Terms & Conditions",
                "item": cityConfig.base_url + location.pathname,
              }
            ]}
          }}
        />
        <h2 className={styles.PrivacyPage_header}>Terms & Conditions</h2>
        <TermsText className={styles.AboutPage_body} cityConfig={cityConfig} />
      </div>
    );
  }
}

Terms.propTypes = {
  ui: PropTypes.object,
  clearServerRenderedPath: PropTypes.func,
  cityConfig: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    ui: state.ui,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
